import React from "react"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const SmallBlueCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#440099"
    isSolid={true}
    className="partner-about-circle partner-about-circle--small-blue"
  />
)
export const MiddleBlueCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#440099"
    className="partner-about-circle partner-about-circle--middle-blue"
  />
)
export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#009B3A"
    isSolid={true}
    className="partner-about-circle partner-about-circle--small-green"
  />
)
export const BigYellowCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#FFDD00"
    className="partner-about-circle partner-about-circle--big-yellow"
  />
)
