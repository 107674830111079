import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ReactComponent as AppStore } from "@images/icons/app-store.svg"
import { ReactComponent as GooglePlay } from "@images/icons/google-play.svg"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./apps-section.scss"

type AppsSectionPropsTypes = PartnerPageBlocksDataType

const AppsSection: React.FC<AppsSectionPropsTypes> = ({
  image,
  title,
  appStoreLink,
  googlePlayLink,
}) => {
  return (
    <section className="container apps-section">
      <div className="apps-section__picture">
        {image && (
          <GatsbyImage
            className="apps-section__image"
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={title}
          />
        )}
      </div>
      <div className="apps-section__info">
        <h2
          className="apps-section__title text text--h1"
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        ></h2>
        {appStoreLink && (
          <a
            className="apps-section__button"
            title="Lolo on App Store"
            href={appStoreLink}
          >
            <AppStore className="apps-section__app-icon" />
          </a>
        )}
        {googlePlayLink && (
          <a
            className="apps-section__button"
            title="Lolo on Google Play"
            href={googlePlayLink}
          >
            <GooglePlay className="apps-section__app-icon" />
          </a>
        )}
      </div>
    </section>
  )
}

export default AppsSection
