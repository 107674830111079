import React from "react"
import Footer from "../../UI/Footer"
import Circle from "../../Circle"
import "./franchise-bottom.scss"

interface FranchiseBottomSectionPropsTypes {
  footerTitle: string
  socialLink: {
    name: string
    title: string
    url: string
  }[]
}

const FranchiseBottom: React.FC<FranchiseBottomSectionPropsTypes> = ({
  footerTitle,
  socialLink,
}) => {
  return (
    <div className="partner-page-footer__wrapper">
      <div className="container partner-page-footer__container">
        <Footer
          className="partner-page-footer"
          title={footerTitle}
          socialLink={socialLink}
        />
        <div className="partner-page-footer-circle partner-page-footer-circle--big-blue">
          <Circle color="#431B93" />
        </div>
        <div className="partner-page-footer-circle partner-page-footer-circle--small-green">
          <Circle color="#009B3A" />
        </div>
      </div>
    </div>
  )
}

export default FranchiseBottom
