import React from "react"
import Hero from "./Hero"
import About from "./About"
import CtaSection from "./CtaSection"
import HumanCta from "./HumanCta"
import Map from "./Map"
import AppsSection from "./AppsSection"
import {
  PartnerPageBlocksDataType,
  PARTNERS_BLOCKS,
} from "@typings/requests/home/partner-page"

interface CountryPartnersBlocksPropsTypes {
  block: PartnerPageBlocksDataType
}

const CountryPartnersBlocks: React.FC<CountryPartnersBlocksPropsTypes> = ({
  block,
}) => {
  const partnerBlock: string = block.strapi_component.replace(
    "partner-page.partner-",
    ""
  )

  const getBlock = () => {
    switch (partnerBlock) {
      case PARTNERS_BLOCKS.HERO:
        return <Hero {...block} />
      case PARTNERS_BLOCKS.ABOUT:
        return <About {...block} />
      case PARTNERS_BLOCKS.CTA_SECTION:
        return <CtaSection {...block} />
      case PARTNERS_BLOCKS.CTA_HUMAN:
        return <HumanCta {...block} />
      case PARTNERS_BLOCKS.MAP:
        return <Map {...block} />
      case PARTNERS_BLOCKS.APPS:
        return <AppsSection {...block} />
      default:
        break
    }
  }

  // TS requires such construction
  // otherwise at the page/home/{strapiPartnerPage}
  // will be error that CountryPartnersBlocks is not a JSX component

  return <>{getBlock()}</>
}

export default CountryPartnersBlocks
