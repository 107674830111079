import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ButtonLink from "../../UI/Buttons/ButtonLink"
import { SmallGreenCircle } from "./HumanCtaCircles"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./human-cta.scss"

type HumanCtaSectionPropsTypes = PartnerPageBlocksDataType

const HumanCta: React.FC<HumanCtaSectionPropsTypes> = ({
  photo,
  title,
  description,
  button,
}) => {
  return (
    <section className="partner-human-cta">
      <div className="container partner-human-cta__wrapper">
        <div className="partner-human-cta__picture">
          <SmallGreenCircle />
          {photo && (
            <GatsbyImage
              className="partner-human-cta__image gatsby-image"
              image={photo.localFile.childImageSharp.gatsbyImageData}
              alt={title}
            />
          )}
        </div>
        <div className="partner-human-cta__text">
          <h2
            className="text text--h2 text--white partner-human-cta__title"
            dangerouslySetInnerHTML={{ __html: sanitize(title) }}
          ></h2>
          <div
            className="text text--white partner-human-cta__description"
            dangerouslySetInnerHTML={{
              __html: sanitize(description?.data.description),
            }}
          ></div>
          {button && (
            <ButtonLink
              className={"partner-human-cta__button"}
              isLocal={true}
              href={button.url}
              label={button.label}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default HumanCta
