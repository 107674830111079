import React from "react"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigBlueCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#440099"
    className="partner-hero-circle partner-hero-circle--big-blue"
    isSolid={true}
  />
)
export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#009B3A"
    isSolid={true}
    className="partner-hero-circle partner-hero-circle--small-green"
  />
)
export const SmallYellowCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#FFDD00"
    className="partner-hero-circle partner-hero-circle--small-yellow"
  />
)
