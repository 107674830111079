import React from "react"
import ButtonLink from "../../UI/Buttons/ButtonLink"
import {
  BigBlueCircle,
  SmallGreenCircle,
  SmallYellowCircle,
} from "./HeroCircles"
import { ReactComponent as Lolo } from "@images/icons/logo_top.svg"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./hero.scss"

type HeroSectionPropsTypes = PartnerPageBlocksDataType

const Hero: React.FC<HeroSectionPropsTypes> = ({
  title,
  countryForDescription,
  button,
}) => {
  return (
    <section className="container partner-hero">
      <BigBlueCircle />
      <SmallGreenCircle />
      <SmallYellowCircle />

      <div className="container__item">
        <h1 className="text text--h0 partner-hero__title">
          <span dangerouslySetInnerHTML={{ __html: sanitize(title) }}></span>
        </h1>
        <p className="text text--h2 partner-hero__subtitle">
          <span className="partner-hero__subtitle--green">Ride. </span>
          <span className="partner-hero__subtitle--blue">Explore. </span>
          <span className="partner-hero__subtitle--main">Act. </span>
          <br /> together with&nbsp;
          <Lolo className="partner-hero__subtitle-logo" />
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(countryForDescription),
            }}
          ></span>
        </p>
        {button && (
          <ButtonLink
            className="partner-hero__button"
            isLocal={true}
            href={button.url}
            label={button.label}
          />
        )}
      </div>
    </section>
  )
}

export default Hero
