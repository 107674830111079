import React from "react"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#009B3A"
    className="partner-human-cta-circle partner-human-cta-circle--small-green"
  />
)
