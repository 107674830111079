import React, { useMemo } from "react"
import { BigGreenCircle } from "./MapCircles"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./map.scss"

type MapSectionPropsTypes = PartnerPageBlocksDataType

const Map: React.FC<MapSectionPropsTypes> = ({
  points,
  title,
  center,
  zoom,
}) => {
  const memoizedPoints = useMemo(() => {
    return points?.strapi_json_value.reduce(
      (accumulator, item) =>
        (accumulator += `&markers=scale:2|icon:https://ridelolo-images.s3.eu-west-1.amazonaws.com/pointerx2.png%7C${item.lat},${item.lng}`),
      ""
    )
  }, [points])

  return (
    <section className="container partner-map">
      <div className="container__item">
        <BigGreenCircle />
        <h2
          className="text text--h1 partner-map__title"
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        />
        <div className="partner-map__map-image">
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${center?.lat},${center?.lng}&zoom=${zoom}&size=1280x326&maptype=roadmap&format=jpeg&scale=2&language=en${memoizedPoints}&key=${process.env.GOOGlE_STATIC_MAP_KEY}`}
            alt={sanitize(title)}
          />
        </div>
      </div>
    </section>
  )
}

export default Map
