import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ButtonLink from "../../UI/Buttons/ButtonLink"
import { ReactComponent as PhoneIcon } from "@images/icons/phone.svg"
import { ReactComponent as AddressIcon } from "@images/icons/location.svg"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./cta-section.scss"

type CtaSectionPropsTypes = PartnerPageBlocksDataType

const CtaSection: React.FC<CtaSectionPropsTypes> = ({
  title,
  image,
  button,
  partnerCtaPhone,
  partnerCtaAddress,
}) => {
  return (
    <section className="container partner-cta-section">
      <div className="partner-cta-section__info">
        <h2
          className="partner-cta-section__title text text--h1"
          dangerouslySetInnerHTML={{ __html: sanitize(title) }}
        ></h2>
        {image && (
          <GatsbyImage
            className="partner-cta-section__image partner-cta-section__image--mobile"
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={title}
          />
        )}
        {button && (
          <ButtonLink
            className="partner-cta-section__button"
            isLocal={true}
            href={button.url}
            label={button.label}
          />
        )}
        {partnerCtaPhone?.length && (
          <ul className="partner-cta-section__list list-unstyled">
            {partnerCtaPhone.map(phone => (
              <li
                key={phone.phoneNumber}
                className="partner-cta-section__list-item list-unstyled"
              >
                <a
                  href={`tel:${phone.phoneNumber.replace(/ /g, "")}`}
                  className="text text--h2 partner-cta-section__phone partner-phone-link"
                >
                  <PhoneIcon className="partner-cta-section__list-icon" />
                  {phone.phoneNumber}
                </a>
              </li>
            ))}
            {partnerCtaAddress?.map(address => (
              <li
                key={address.link}
                className="partner-cta-section__list-item list-unstyled"
              >
                <a
                  href={address.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text text--h4 partner-cta-section__phone partner-phone-link"
                >
                  <AddressIcon className="partner-cta-section__list-icon" />
                  {address.address}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="partner-cta-section__picture">
        {image && (
          <GatsbyImage
            className="partner-cta-section__image gatsby-image"
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={title}
          />
        )}
      </div>
    </section>
  )
}

export default CtaSection
