import React from "react"
import {
  SmallBlueCircle,
  MiddleBlueCircle,
  SmallGreenCircle,
  BigYellowCircle,
} from "./AboutCircles"
import { sanitize } from "@helpers/helpers"
import { PartnerPageBlocksDataType } from "@typings/requests/home/partner-page"
import "./about.scss"

type AboutSectionPropsTypes = PartnerPageBlocksDataType

const About: React.FC<AboutSectionPropsTypes> = ({ partnerAboutItem }) => {
  return (
    <section className="container partner-about">
      <SmallBlueCircle />
      <ul className="list-unstyled partner-about-list container__item">
        {partnerAboutItem?.map((item, key) => (
          <li
            className="partner-about-list__item"
            key={item.title.replace(/ /g, "")}
          >
            {key === 1 && <BigYellowCircle />}
            {key === 2 && <SmallGreenCircle />}
            {key === 3 && <MiddleBlueCircle />}
            <h2
              className="text text--h1 partner-about-list__title"
              dangerouslySetInnerHTML={{ __html: sanitize(item.title) }}
            ></h2>
            <p
              className="text partner-about-list__description"
              dangerouslySetInnerHTML={{
                __html: sanitize(item.description.data.description),
              }}
            ></p>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default About
