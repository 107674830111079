import {
  ButtonLinkType,
  FooterDataType,
  IGatsbyImagePropsType,
  InnerDataDescriptionType,
} from "@typings/index"

export enum PARTNERS_BLOCKS {
  HERO = "hero",
  ABOUT = "about",
  CTA_SECTION = "cta-section",
  CTA_HUMAN = "human-cta",
  MAP = "map",
  APPS = "apps",
}

export interface PartnerPageBlocksDataType {
  id: string
  strapi_component: string

  appStoreLink?: string
  title: string
  googlePlayLink?: string
  image?: IGatsbyImagePropsType
  partnerCtaPhone?: {
    phoneNumber: string
  }[]
  partnerCtaAddress?: {
    link: string
    address: string
  }[]
  countryForDescription?: string
  description?: InnerDataDescriptionType
  photo?: IGatsbyImagePropsType
  center?: {
    lat: number
    lng: number
  }
  zoom?: number
  points?: {
    strapi_json_value: {
      lat: number
      lng: number
    }[]
  }
  partnerAboutItem?: {
    title: string
    description: InnerDataDescriptionType
  }[]
  button?: ButtonLinkType
}

export interface CountryPartnerDataType extends FooterDataType {
  strapiPartnerPage: {
    slug: string
    title: string
    metaImage: {
      url: string
    }
    whiteBackground: string
    description: string
    socialLink: {
      name: string
      title: string
      url: string
    }[]
    partnerPageBlocks: PartnerPageBlocksDataType[]
  }
}
