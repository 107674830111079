import React from "react"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigGreenCircle: React.FC<AnimatedCirclePropsTypes> = () => (
  <Circle
    color="#009B3A"
    className="partner-map-circle partner-map-circle--big-green"
  />
)
