import React from "react"
import { graphql, PageProps } from "gatsby"
import cn from "classnames"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import CountryPartnerBlocks from "@components/FranchisePage"
import FranchiseBottom from "@components/FranchisePage/FranchiseBottom"
import { CountryPartnerDataType } from "@typings/requests/home/partner-page"
import "./partner-page.scss"

const CountryPartnerPage: React.FC<PageProps<CountryPartnerDataType>> = ({
  location,
  data,
}) => {
  const {
    strapiPartnerPage: countryPartnerData,
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = data
  const { partnerPageBlocks, socialLink } = countryPartnerData

  return (
    <>
      <SEO
        title={countryPartnerData.title}
        description={countryPartnerData.description}
        image={countryPartnerData.metaImage.url}
        pathname={location.pathname}
        lang="en"
      />
      <LayoutContainer
        className={cn("partner-page", {
          "partner-page--white": countryPartnerData.whiteBackground,
        })}
        location={location}
        hideLangSwitcher
      >
        {partnerPageBlocks?.map(block => (
          <CountryPartnerBlocks
            block={block}
            key={block.strapi_component + block.id}
          />
        ))}
        <FranchiseBottom
          footerTitle={footerData.title}
          socialLink={socialLink}
        />
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  query PartnerPageQuery($slug: String!) {
    strapiPartnerPage(slug: { eq: $slug }) {
      slug
      title
      metaImage {
        url
      }
      whiteBackground
      description
      socialLink {
        name
        title
        url
      }
      partnerPageBlocks {
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_APPS {
          id
          strapi_component
          appStoreLink
          title
          googlePlayLink
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 497
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 95
                )
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_CTA_SECTION {
          id
          strapi_component
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 674
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 95
                )
              }
            }
          }
          partnerCtaPhone {
            phoneNumber
          }
          partnerCtaAddress {
            link
            address
          }
        }
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_HERO {
          id
          strapi_component
          countryForDescription
          title
        }
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_HUMAN_CTA {
          id
          strapi_component
          title
          description {
            data {
              description
            }
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 438
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 95
                )
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_MAP {
          id
          strapi_component
          center {
            lat
            lng
          }
          title
          zoom
          points {
            strapi_json_value {
              lat
              lng
            }
          }
        }
        ... on STRAPI__COMPONENT_PARTNER_PAGE_PARTNER_ABOUT {
          id
          partnerAboutItem {
            title
            description {
              data {
                description
              }
            }
          }
          strapi_component
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
      }
    }
  }
`

export default CountryPartnerPage
